import ApplePay from '../applepay/applepay';

const payWithApplePayButton = document.querySelector('#payWithApplePay') as HTMLButtonElement;

export const cartPage = () => {
    if (payWithApplePayButton != null) {
        new ApplePay(null, null, payWithApplePayButton);
        payWithApplePayButton.addEventListener('click', setSessionVariable);
    }

    function setSessionVariable(event: Event): void {
        let redirectUrl = (event.target as HTMLButtonElement).getAttribute('data-apple-pay-redirect-url');
        let apiUrl = "/ace-api/cart/UpdateApplePaySessionVariable?redirectUrl=" + redirectUrl;
        window.location.href = apiUrl;
    }
}