class PromoInterface {
    container: HTMLElement;
    trigger: HTMLButtonElement;
    panel: HTMLElement;
    hasError: boolean;

    constructor(container: HTMLElement) {
        this.container = container;
        this.trigger = container.querySelector('[data-promo-entry]') as HTMLButtonElement;
        this.panel = container.querySelector('[data-promo-input]') as HTMLElement;

        if (this.trigger != undefined) {
            this.hasError = this.trigger.classList.contains('hide');

            if (!this.hasError) {
                this.trigger.addEventListener('click', () => {
                    this.trigger.classList.add('hide');
                    this.panel.classList.remove('hide');
                })
            }
        }
    }
}

const promoEls = [].slice.call(document.querySelectorAll('[data-promo]')) as HTMLElement[];
promoEls.forEach(promoEl => {
    new PromoInterface(promoEl);
})