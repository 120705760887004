let donation = '0';
const currentTotal = document.querySelector('#CurrentOrderTotalValue') as HTMLInputElement;
const totals = document.querySelector('#DonationTotal') as HTMLElement;
const donationInput = document.querySelector('#SuggestedContribution') as HTMLInputElement;

export const donationInterruptPage = () => {
    if (donationInput != null) {
        donationInput.addEventListener('change', getDonationWithTotal);
    }
}

const getDonationWithTotal = (e) => {
    donation = donationInput.value;
    const donationValue = parseFloat(donation);
    try {
        const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        if (isNaN(donationValue)) {
            totals.innerHTML = currencyFormatter.format(parseFloat(currentTotal.value));
        } else {
            const updatedTotal = currencyFormatter.format(parseFloat(currentTotal.value) + donationValue);
            totals.innerHTML = updatedTotal;
        }
    } catch (ex) {
        console.error(`Error: ${ex}`);
    }

    if (!isNaN(donationValue)) {
        donationInput.value = donationValue.toFixed(2);
    } else {
        donationInput.value = "0";
    }
};
