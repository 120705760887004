const exchangeFormId = 'exchangeTicketsForm';

export const selectTickets = () => {
    if (document.getElementById(exchangeFormId) != null) {
        let checkBoxes = getInputElements(exchangeFormId, 'checkbox');
        let radioButtons = getInputElements(exchangeFormId, 'radio');
        checkBoxes.forEach(function (element) {
            element.addEventListener("click", updateSeatingSelection);
        });
        radioButtons.forEach(function (element) {
            element.addEventListener('click', updatePerformanceSelection)
        })
    }
}

const updateSeatingSelection = (event) => {
    if (event.target.checked == true) {
        disableOtherPriceTypes(getPriceType(event.target));
        getCurrentCta().disabled = false;
    } else {
        let boxes = getInputElements(exchangeFormId, 'checkbox');
        if (!boxes.some((e) => e.checked)) {
            boxes.forEach((e) => { e.disabled = false; });
            getCurrentCta().disabled = true;
        }
    }
}

const updatePerformanceSelection = (event) => {
    let checkedId = +event.target.getAttribute('production');
    let continueButtons = getInputElements(exchangeFormId, 'submit'); 
    if (continueButtons.length < 2)
        return;
    if (continueButtons.some((e) => e.disabled === false)) {
        continueButtons.forEach(function (item) {
            let buttonId = +item.getAttribute('data-id');
            if (buttonId === checkedId) {
                item.disabled = false;
            } else {
                item.disabled = true;
            }
        });
    }
}

function disableOtherPriceTypes(priceTypeId: number): void {
    let checkBoxes = getInputElements(exchangeFormId, 'checkbox');
    checkBoxes.forEach(function (element) {
        if (getPriceType(element) !== priceTypeId) {
            element.setAttribute('disabled', '');
        }
    });
}

function getCurrentCta(): HTMLInputElement {
    let continueButtons = getInputElements(exchangeFormId, 'submit');
    if (continueButtons.length == 1)
        return continueButtons[0];
    let radioButtons = getInputElements(exchangeFormId, 'radio');
    let checkedId = 0;
    radioButtons.forEach(function (item) {
        if (item.checked) {
            checkedId = +item.getAttribute('production');
        }
    });
    let currentCta: HTMLInputElement = null;
    continueButtons.forEach(function (item) {
        if (+item.getAttribute('data-id') === checkedId) {
            currentCta = item;
        }
    });
    return currentCta;
}

function getInputElements(parentElementId: string, inputType: string): HTMLInputElement[] {
    let nodes = document.querySelectorAll("#" + parentElementId + " [type='" + inputType + "']");
    let arr: HTMLInputElement[] = [];
    for (let i = 0; i < nodes.length; i++) {
        arr[i] = nodes[i] as HTMLInputElement;
    }
    return arr;
}

function getPriceType(e: Element): number {
    return parseInt(e.getAttribute("priceType").replace(/[^0-9\.]+/g, ""), 10);
}