export class StateDropdown {
    stateDropdown: HTMLSelectElement;
    countryDropdown: HTMLSelectElement;
    stateList: HTMLOptionElement[];
    currentCountryId: string;
    freshStateDropdown: HTMLSelectElement;

    constructor(stateDropdown: HTMLSelectElement, countryDropdown: HTMLSelectElement) {
        this.stateDropdown = stateDropdown;
        this.freshStateDropdown = stateDropdown.cloneNode(true) as HTMLSelectElement;
        this.countryDropdown = countryDropdown;
        this.appendStateless();
        this.stateList = Array.prototype.slice.call(this.stateDropdown.querySelectorAll('option'));
        this.currentCountryId = countryDropdown.value;

        this.countryDropdown.addEventListener('change', () => {
            this.currentCountryId = this.countryDropdown.value;
            this.filterStates();
        });

        this.filterStates();
    }

    appendStateless() {
        const blankOption = document.createElement('option');
        blankOption.value = "";
        blankOption.dataset.country = "stateless";
        blankOption.innerHTML = "State";
        this.stateDropdown.appendChild(blankOption);
    }

    filterStates() {
        const newSelect = this.freshStateDropdown.cloneNode(true) as HTMLSelectElement;
        let newSelectOptions = Array.prototype.slice.call(newSelect.querySelectorAll('option')) as HTMLOptionElement[];
        newSelectOptions.forEach(state => {
            if (state.dataset.country != this.currentCountryId) {
                // IE doesn't support .remove() here
                state.parentNode.removeChild(state);
            }
        });

        this.stateDropdown.parentNode.replaceChild(newSelect, this.stateDropdown);
        this.stateDropdown = newSelect;

        if (newSelect.options.length == 0) {
            this.appendStateless();
            this.stateDropdown.setAttribute('disabled', 'true');
        }
        else {
            this.stateDropdown.removeAttribute('disabled');
        }

        //this.stateDropdown.selectedIndex = 0;
    }
}