export const selectPerformance = () => {
    let selectPerformanceCTAs = document.querySelectorAll('.ace-exchanges-productions-list-item-header-details-cta-btn');
    if (selectPerformanceCTAs != null) {
        for (var i = 0; i < selectPerformanceCTAs.length; i++) {
            selectPerformanceCTAs[i].addEventListener('click', function (event) {
                let currentID = this.getAttribute('data-id'),
                    textToShow = this.getAttribute('data-textToShow'),
                    textToHide = this.getAttribute('data-textToHide');
                let elementToShow = document.querySelector('.ace-exchanges-productions-list-item[data-id="' + currentID + '"');
                elementToShow.classList.toggle('active');
                if (this.innerHTML === textToShow) {
                    this.innerHTML = textToHide;
                } else {
                    this.innerHTML = textToShow;
                }
            });
        }
    }
};