import { nodeListToArray } from "../../Content/helpers/nodeListToArray";

export class ShowHideItems {
    private showHideTriggers: Array<HTMLButtonElement>;
    private elementToToggle: HTMLElement;
    constructor() {
        this.showHideTriggers = nodeListToArray(document.querySelectorAll("[data-show-more-btn]"));
        if (this.showHideTriggers.length > 0) {
            this.activateTriggers();
        }
    }
    activateTriggers() {
        this.showHideTriggers.forEach(trigger => {
            trigger.addEventListener('click', () => {
                this.toggleItemVisibility(trigger);
            });
        });
    }
    toggleDataShownAttribute(currentAttribute: string) {
        if (currentAttribute === 'true')
            return 'false'
        return 'true';
    }
    toggleItemVisibility(trigger: HTMLButtonElement) {
        this.elementToToggle = document.getElementById(trigger.getAttribute("data-element-to-toggle"));
        const currentlyHiddenItems: Array<HTMLElement> = nodeListToArray(this.elementToToggle.querySelectorAll(".hide"));
        const currentlyShownItems: Array<HTMLElement> = nodeListToArray(this.elementToToggle.querySelectorAll("[data-shown=true]"));
        let itemsToUpdate: Array<HTMLElement>;
        let triggerText: string;
        if (currentlyHiddenItems.length > 0) {
            itemsToUpdate = currentlyHiddenItems;
            triggerText = trigger.dataset.showLessText;
            this.elementToToggle.tabIndex = -1;
            this.elementToToggle.focus();
        }
        else {
            itemsToUpdate = currentlyShownItems;
            triggerText = trigger.dataset.showMoreText;
        }
        itemsToUpdate.forEach(item => {
            item.classList.toggle('hide');
            item.setAttribute('data-shown', this.toggleDataShownAttribute(item.getAttribute('data-shown')));
        });
        trigger.innerText = triggerText;
    }
};
