export const accordionBlock = () => {
    let accordions = Array.prototype.slice.call(document.querySelectorAll('[data-accordion]'));
    // Create list of accordion Items
    let accordionItems = accordions.map(accordion => Object.freeze({
        minimum: parseInt(accordion.getAttribute("data-minimum")),
        maximum: parseInt(accordion.getAttribute("data-maximum")),
        content: accordion.querySelector(".ace-accordion-content"),
        inner: accordion.querySelector("[data-accordion-inner]"),
        trigger: accordion.querySelector("[data-accordion-trigger]"),
    }));

    initializeAccordionItems(accordionItems);
};

export const initializeAccordionItems = (accordionItems) => {
    if (!accordionItems)
        return;

    // Register accordion events for each membership level
    [].forEach.call(accordionItems, accordionItem => {


        // Set up trigger to toggle toggle open/closed classes and ARIA attributes
        accordionItem.trigger.addEventListener('click', (e) => {
            if (accordionItem.inner.classList.contains('open')) {
                accordionItem.inner.classList.remove('open');
                accordionItem.trigger.setAttribute('aria-expanded', 'false');
                accordionItem.content.setAttribute('aria-hidden', 'true');

            }
            else {
                accordionItem.inner.classList.add('open');
                accordionItem.trigger.setAttribute('aria-expanded', 'true');
                accordionItem.content.setAttribute('aria-hidden', 'false');
            }
        });
    });
}