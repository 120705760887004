import { StateSummary } from '../classes/address';
import { AceLoader } from './ace-loader';
import AddressApi from './address-api';

export class AddressModal {
    modalElement: HTMLElement;
    loader: AceLoader;
    guestNameRequired: boolean;
    invalidFields: Array<string>;
    validationSummary: HTMLElement;

    constructor(modalElement: HTMLElement, container?: HTMLElement) {
        this.modalElement = modalElement;
        this.loader = new AceLoader();

        let saveAddressButtons = modalElement.querySelectorAll('[data-save-btn]');
        if (saveAddressButtons != null) {
            for (var i = 0; i < saveAddressButtons.length; i++) {
                saveAddressButtons[i].addEventListener('click', this.saveAddressForm.bind(this));
            }
        }

        let country = (<HTMLSelectElement>this.modalElement.querySelector('[name=country'));
        country.addEventListener('change', this.getStatesByCountry.bind(this));

        let guestNameRequired = (<HTMLInputElement>this.modalElement.querySelector('[name=guestNameRequired'))
        if (guestNameRequired)
            this.guestNameRequired = guestNameRequired.value.toLowerCase() === 'true'

        this.validationSummary = (<HTMLElement>this.modalElement.querySelector('.validation-summary-errors'));
    }

    saveAddressForm = (e) => {
        e.preventDefault();
        this.resetValidationSummary();
        let validName = this.validateName();
        if (this.validateAddress() && validName) {
            let firstName = (<HTMLInputElement>this.modalElement.querySelector('[name=firstName'))?.value;
            let lastName = (<HTMLInputElement>this.modalElement.querySelector('[name=lastName'))?.value;
            let id = (<HTMLInputElement>this.modalElement.querySelector('[name=id')).value;
            let isPrimary = (<HTMLInputElement>this.modalElement.querySelector('[name=isPrimary'))?.value;
            let address1 = (<HTMLInputElement>this.modalElement.querySelector('[name=address1')).value;
            let address2 = (<HTMLInputElement>this.modalElement.querySelector('[name=address2')).value;
            let city = (<HTMLInputElement>this.modalElement.querySelector('[name=city')).value;
            let state = (<HTMLSelectElement>this.modalElement.querySelector('[name=state')).value;
            let zipcode = (<HTMLInputElement>this.modalElement.querySelector('[name=zipCode')).value;
            let country = (<HTMLSelectElement>this.modalElement.querySelector('[name=country')).value;

            var phoneLabel = (<HTMLSelectElement>this.modalElement.querySelector('[name=phone'));

            let phone = "";
            if (phoneLabel)
                phone = phoneLabel.value;

            this.loader.show();
            return AddressApi.updateAddress(+id, address1, address2,
                city, +state, zipcode, +country, phone, isPrimary == "true", firstName, lastName).then(() => {
                    window.location.reload();
                });
        }
        else {
            this.displayValidationSummary();
        }
    };

    validateName = () => {
        if (!this.guestNameRequired)
            return true;

        let firstName = (<HTMLInputElement>this.modalElement.querySelector('[name=firstName'));
        let lastName = (<HTMLInputElement>this.modalElement.querySelector('[name=lastName'));

        let firstNameValid = this.validateField(firstName);
        let lastNameValid = this.validateField(lastName);

        return firstNameValid && lastNameValid;
    }

    validateAddress = () => {
        let address1 = (<HTMLInputElement>this.modalElement.querySelector('[name=address1'));
        let city = (<HTMLInputElement>this.modalElement.querySelector('[name=city'));
        let state = (<HTMLSelectElement>this.modalElement.querySelector('[name=state'));
        let zipcode = (<HTMLInputElement>this.modalElement.querySelector('[name=zipCode'));
        let country = (<HTMLSelectElement>this.modalElement.querySelector('[name=country'));

        let address1valid = this.validateField(address1);
        let cityValid = this.validateField(city);
        let stateValid = this.validateField(state) || state.disabled;
        let zipValid = this.validateField(zipcode);
        let countryValid = this.validateField(country);

        return address1valid && cityValid && stateValid && zipValid && countryValid;
    }

    validateField = (element: HTMLElement) => {
        if (!element)
            return false;
        let input = element as HTMLInputElement;
        let select = element as HTMLSelectElement;

        if ((input && (!input.value || !input.value.length)) ||
            (select && (!select.value || !select.value.length))) {
            let ename = element.attributes['name'].value;
            let label = this.modalElement.querySelector(`[for=${ename}]`);
            if (label) {
                this.invalidFields.push(label.innerHTML);
            } else {
                this.invalidFields.push(ename);
            }
            return false;
        }
        return true;
    }

    resetValidationSummary = () => {
        if (this.validationSummary) {
            this.validationSummary.innerHTML = "";
            this.validationSummary.classList.add('hide');
        }
        this.invalidFields = [];       
    }

    displayValidationSummary = () => {
        let validationMessage = "The following fields are required: ";
        validationMessage += this.invalidFields.join(', ');
        if (this.validationSummary) {
            this.validationSummary.innerHTML = validationMessage;
            this.validationSummary.classList.remove('hide');
        }
    }

    getStatesByCountry = (e: Event) => {

        let country = (<HTMLSelectElement>e.currentTarget).value;
        AddressApi.getStatesForCountry(+country).then(results => {
            this.updateStatesDropDown(results)
        })
    }

    updateStatesDropDown = (states: Array<StateSummary>) => {
        let stateDropDown = (<HTMLSelectElement>this.modalElement.querySelector('[name=state'));

        stateDropDown.innerHTML = '';
        if (states.length) {
            states.forEach(state => {
                var option = document.createElement('option');
                option.value = state.id.toString();
                option.innerHTML = state.description;
                stateDropDown.appendChild(option);
            });
            stateDropDown.disabled = false;
        }
        else {
            stateDropDown.disabled = true;
        }
    }
}