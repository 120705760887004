import { nodeListToArray } from "../helpers/nodeListToArray";

export default function scrollToErrors() {
    let submitButtons = nodeListToArray(document.querySelectorAll("[data-recaptcha-button]"));
    submitButtons = submitButtons.concat(nodeListToArray(document.querySelectorAll("button[type=submit]")));
    submitButtons.forEach(button => {
        button.addEventListener('click', () => {
            setTimeout(() => {
                const errors = nodeListToArray(document.querySelectorAll("[aria-invalid=true]"));
                if (errors.length > 0)
                    errors[0].focus();
            }, 100)
        });
    });
}