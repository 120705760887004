
export class Alert {
    alertElement: HTMLElement;

    constructor(alertElement: HTMLElement, container?: HTMLElement) {

        this.alertElement = alertElement;

        this.alertElement.querySelector('[data-close]').addEventListener('click', () => {
            this.alertElement.style.display = 'none';
        })
    }
}