var headerMenuTrigger = document.getElementById('mainNavBarNabTrigger'),
    headerMenuTriggerOpen = document.getElementById('mainNavBarNabTriggerOpen'),
    headerMenuTriggerClose = document.getElementById('mainNavBarNabTriggerClose'),
    navMenuContainer = document.getElementById('navigationContainer');


export const headerMenu = () => {
    if (headerMenuTrigger != null) {
        headerMenuTrigger.addEventListener('click', headerMenuTriggerEvent);
    }
}

const headerMenuTriggerEvent = (e) => {
    if (headerMenuTrigger.getAttribute('aria-expanded') == 'false') {
        headerMenuTrigger.setAttribute('aria-expanded', 'true');
        headerMenuTriggerOpen.setAttribute('aria-hidden', 'true');
        headerMenuTriggerClose.setAttribute('aria-hidden', 'false');
        navMenuContainer.setAttribute('aria-hidden', 'false');
    } else {
        headerMenuTrigger.setAttribute('aria-expanded', 'false');
        headerMenuTriggerOpen.setAttribute('aria-hidden', 'false');
        headerMenuTriggerClose.setAttribute('aria-hidden', 'true');
        navMenuContainer.setAttribute('aria-hidden', 'true');
    }
}