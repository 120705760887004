function setItemHtmlandCssClass(items: Array<HTMLElement>, headerValue: string, defaultHeader: string) {
    const headerValueText = (headerValue !== "" && headerValue !== undefined) ? headerValue : defaultHeader;
    const headerCssClass = (headerValue !== "" && headerValue !== undefined) ? "visually-hidden" : "";
    if (items.length > 0) {
        items.forEach(item => {
            item.innerHTML = headerValueText;
            if (headerCssClass !== "")
                item.classList.add(headerCssClass);
        });
    }
}

export const setUpTicketHeaders = () => {
    const windowObj = (window as any);
    const sections = Array.prototype.slice.call(document.querySelectorAll('[data-section-header]'));
    setItemHtmlandCssClass(sections, windowObj.sectionHeader, "Section");

    const rows = Array.prototype.slice.call(document.querySelectorAll('[data-row-header]'));
    setItemHtmlandCssClass(rows, windowObj.rowHeader, "Row");

    const seats = Array.prototype.slice.call(document.querySelectorAll('[data-seat-header]'));
    setItemHtmlandCssClass(seats, windowObj.seatHeader, "Seat");
}