import { AddressModal } from '../../modules/address-modal';

export const profilePage = () => {
    const sectionButtons = [].slice.call(document.querySelectorAll('[data-section]')) as HTMLButtonElement[];
    if (sectionButtons.length > 0) {
        sectionButtons.forEach(sectionButton => {
            sectionButton.addEventListener('click', toggleSectionInputs)
        })
    }
    const hiddenSectionValue = <HTMLInputElement>document.getElementById("hidden-section-type");
    const hiddenUpdateStatus = <HTMLInputElement>document.getElementById("profile-update-fail");

    // If error is thrown my MVC model state
    const hiddenfailedProperty = <HTMLInputElement>document.getElementById("profile-update-fail-property");

    if (hiddenUpdateStatus && hiddenUpdateStatus.value.toLowerCase() == "true") {
        //MVC MODEL STATE FAILURE
        if (hiddenfailedProperty.value != "") {
            const failedFormPropertyElement = document.querySelectorAll(`[name="${hiddenfailedProperty.value}"]`);
            if (failedFormPropertyElement) {
                const failedComponent = (<HTMLInputElement>failedFormPropertyElement[0]).closest("[data-info-group]");
                const triggerButton = failedComponent.querySelector('[data-section]');
                triggerEvent(triggerButton, "click");
            }
            //TESSITURA FAILURE
        } else if (hiddenSectionValue.value != "") {
            const section = document.querySelectorAll(`[data-section="${hiddenSectionValue.value}"]`)[0];
            triggerEvent(section, "click");
        }

    }

    const modals = Array.prototype.slice.call(document.querySelectorAll('[data-address-modal]')) as HTMLElement[];
    modals.forEach(modal => {
        new AddressModal(modal);
    });
}

const triggerEvent = (element: Element, eventName) => {
    const event = new Event(eventName);
    element.dispatchEvent(event);
}
const toggleSectionInputs = (e) => {
    const sectionID = e.currentTarget.getAttribute('data-section');
    const hiddenSectionValue = (<HTMLInputElement>document.getElementById("hidden-section-type"));
    const activeEdit = e.currentTarget.getAttribute('aria-expanded') === 'true';
    const sectionInfo = document.querySelectorAll(`[data-section-info="${sectionID}"]`);
    const sectionInputs = document.querySelectorAll(`[data-section-input="${sectionID}"]`);

    if (hiddenSectionValue)
        hiddenSectionValue.value = sectionID;

    if (sectionInfo.length > 0) {
        sectionInfo[0].setAttribute('aria-hidden', (!activeEdit).toString());
    }

    if (sectionInputs.length > 0) {
        sectionInputs[0].setAttribute('aria-hidden', activeEdit.toString());
    }

    e.currentTarget.setAttribute('aria-expanded', (!activeEdit));

    if (activeEdit) {
        e.currentTarget.innerText = e.currentTarget.getAttribute('data-edit-text');
    } else {
        e.currentTarget.innerText = e.currentTarget.getAttribute('data-cancel-text');
    }

}