import { donationInterruptPage } from './pages/donationInterruptPage/donationInterruptPage';
import { profilePage } from './pages/profilePage/profilePage';
import { headerMenu } from './modules/header-menu';
import { Alert } from './modules/ace-alert';
import { accordionBlock } from './modules/ace-accordion';
import { blockClicksOnLoad } from './modules/block-clicks-on-load'; 
import { showHidePassword } from './modules/show-hide-password';
import { selectPerformance } from './pages/exchangesPage/exchangesPage-selectPerformance';
import { selectTickets } from './pages/exchangesPage/exchangesPage-selectTickets';
import { quickDonate } from './pages/quickDonate/quickDonatePage';
import { headerContent } from './modules/header-content'
import { setUpTicketHeaders } from './modules/setUpTicketHeaders';
import CartTimer from './modules/cart-timer';
import { Modal } from './modules/a11y-modal';
import { StateDropdown } from './modules/stateDropdown';
import { RevealPanel } from './modules/revealPanel';
import { donationBlock } from './modules/donate-block';
import './modules/promo-interface';
import { StickySidebarComponent } from './modules/sticky-sidebar-component';
import { ShowHideItems } from './modules/show-hide-items';
import scrollToErrors from './modules/scrollToErrors';
import { cartPage } from './pages/cartPage/cartPage-payWithApplePay';

new CartTimer();
donationInterruptPage();
profilePage();
cartPage();
headerMenu();
accordionBlock();
headerContent();
blockClicksOnLoad();
showHidePassword();
selectPerformance();
selectTickets();
quickDonate();
setUpTicketHeaders();
new donationBlock();
new ShowHideItems();
scrollToErrors();
const modals = Array.prototype.slice.call(document.querySelectorAll('[data-modal]')) as HTMLElement[];
modals.forEach(modal => {
    new Modal(modal);
});

const alerts = Array.prototype.slice.call(document.querySelectorAll('[data-alert]')) as HTMLElement[];
alerts.forEach(alert => {
    new Alert(alert);
});

const panels = [].slice.call(document.querySelectorAll('[data-panel]')) as HTMLElement[];
panels.forEach(panel => {
    new RevealPanel(panel);
})

const stateDropdown = document.querySelector('#State') as HTMLSelectElement;
const countryDropdown = document.querySelector('#Country') as HTMLSelectElement;
if (stateDropdown && countryDropdown) {
    new StateDropdown(stateDropdown, countryDropdown);
}

const stickyContainerEls = [].slice.call(document.querySelectorAll('[data-sticky-el]')) as HTMLElement[];
stickyContainerEls.forEach(stickyEl => {
    new StickySidebarComponent(stickyEl);
})