import { nodeListToArray } from "../../Content/helpers/nodeListToArray";

export class donationBlock {
    donateBlocks: HTMLElement[];
    regex: RegExp = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);
    constructor() {
        this.donateBlocks = nodeListToArray(document.querySelectorAll('[data-donate-block]'));
        this.donateBlocks.forEach(donateBlock => {
            var donationId = donateBlock.dataset.donateBlock;
            var donationRadioAmount = nodeListToArray(document.querySelectorAll(`[data-radio-amount="${donationId}"]`)) as HTMLInputElement[];
            var customDonationAmount = document.querySelector(`[data-custom-amount="${donationId}"]`) as HTMLInputElement;
            var donateButton = document.querySelector(`[data-donate-button="${donationId}"]`);
            let donateButtonText;
            let fundId;
            let originalDonationLink;
            if (donateButton) {
                donateButtonText = donateButton.innerHTML;
                originalDonationLink = donateButton.getAttribute('href');
                fundId = parseInt(donateButton.getAttribute('data-fund-id'));
            }
            let newDonationLink = document.querySelector(`[data-donate-button="${donationId}"]`);
            let customAmountMessage = document.querySelector(`[data-custom-amount-message="${donationId}"]`);
            let emptyAmountMessage = document.querySelector(`[data-empty-amount-message="${donationId}"]`);
            var donationRadioAmount = nodeListToArray(document.querySelectorAll(`[data-radio-amount="${donationId}"]`)) as HTMLInputElement[];

            if (donationRadioAmount.length > 0) {
                donationRadioAmount.forEach(donation => {
                    donation.addEventListener('click', (e) => {
                        customDonationAmount.value = "";
                        customAmountMessage.classList.add('hide');
                        emptyAmountMessage.classList.add('hide');
                        newDonationLink.innerHTML = donateButtonText;
                        const donationAmount = parseInt((e.target as HTMLInputElement).value);
                        newDonationLink.setAttribute('href', originalDonationLink + "?amount=" + donationAmount + "&fund=" + fundId);
                        newDonationLink.innerHTML = donateButton.innerHTML + `${this.currencyFormatter(donationAmount)}`;
                    })
                });

                if (donateButton) {
                    donateButton.addEventListener('click', (e) => {
                        if (newDonationLink.getAttribute('href') == originalDonationLink) {
                            newDonationLink.removeAttribute('href');
                            emptyAmountMessage.classList.remove('hide');
                        }
                    });
                }

                if (customDonationAmount) {
                    customDonationAmount.addEventListener('focusout', (e) => {
                        if ((e.target as HTMLInputElement).value == "") {
                            customAmountMessage.classList.add('hide');
                            newDonationLink.setAttribute('href', originalDonationLink);
                            newDonationLink.innerHTML = donateButtonText;
                        }
                    });

                    customDonationAmount.addEventListener('keyup', (e) => {
                        emptyAmountMessage.classList.add('hide');
                        if (!(e.target as HTMLInputElement).value.match(this.regex)) {
                            customAmountMessage.classList.remove('hide');
                            newDonationLink.setAttribute('href', originalDonationLink);
                            newDonationLink.innerHTML = donateButtonText;
                        }
                        else {
                            donationRadioAmount.forEach(radio => {
                                (radio as HTMLInputElement).checked = false;
                            });
                            newDonationLink.innerHTML = donateButtonText;
                            customAmountMessage.classList.add('hide');
                            const donationAmount = parseFloat((e.target as HTMLInputElement).value).toFixed(2);
                            newDonationLink.setAttribute('href', originalDonationLink + "?amount=" + donationAmount + "&fund=" + fundId);
                            newDonationLink.innerHTML = donateButton.innerHTML + `${this.currencyFormatter(parseFloat(donationAmount))}`;
                        }
                    });
                }
            }
        });
    }

    currencyFormatter(amount: number) {
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        });

        return formatter.format(amount);
    }
}