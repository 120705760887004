export const headerContent = () => {
    let headerSections = document.querySelectorAll('[header-content]');
    initializeHeaderSection(headerSections);
};

//vars
export const initializeHeaderSection = (headerSections) => {
    if (!headerSections) return;

    if (!!window.IntersectionObserver) {
        let observerFirstSection = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                handleIntersection(entry)
            });
        }, { threshold: 0, rootMargin: '30% 0px 0px 0px' });

        let observerOtherSections = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                handleIntersection(entry)
            });
        }, { threshold: .45, rootMargin: '30% 0px 0px 0px' });

        headerSections.forEach((headersection, index) => {
            if (index === 0) {
                observerFirstSection.observe(headersection)
            } else {
                observerOtherSections.observe(headersection)
            }
        });
    }

    function handleIntersection(entry) {
        if (entry.isIntersecting) {
            entry.target.setAttribute('data-inview', 'true');
        }
        else {
            entry.target.setAttribute('data-inview', 'false');
        }
    }

    headerSections.forEach(headersection => {
        let sectionOverlay = headersection.querySelector('.header-content-overlay');
        let hasVideo = sectionOverlay.classList.contains('has-video');

        if (hasVideo) {
            setPlaybackControls(headersection, sectionOverlay)
        }
    });

    function playPauseControl(video, controls) {
        if (video != null && video.paused != null && video.paused === true) {
            video.play();

            if (video.loop === null || video.loop === false) {
                video.loop = true;
            }

            setControlVisibility(controls.playButton, controls.playButtonVisual, controls.pauseButton, controls.pauseButtonVisual);
        } else {
            video.pause();
            setControlVisibility(controls.pauseButton, controls.pauseButtonVisual, controls.playButton, controls.playButtonVisual);
        }
    }

    function setPlaybackControls(headersection, sectionOverlay) {
        const video = sectionOverlay.querySelector('video');
        const controls = {
            pauseButton: headersection.querySelector('.header-content-controls-interaction [data-hero-video-pause]') as HTMLButtonElement,
            playButton: headersection.querySelector('.header-content-controls-interaction [data-hero-video-play]') as HTMLButtonElement,
            pauseButtonVisual: headersection.querySelector('.header-content-controls-display [data-hero-video-pause]') as HTMLButtonElement,
            playButtonVisual: headersection.querySelector('.header-content-controls-display [data-hero-video-play]') as HTMLButtonElement,
        }

        controls.pauseButton.addEventListener('click', () => {
            playPauseControl(video, controls);
        });

        controls.playButton.addEventListener('click', () => {
            playPauseControl(video, controls);
        });
    }

    function setControlVisibility(thingToHide: HTMLElement, thingToHideVisual: HTMLElement, thingToShow: HTMLElement, thingToShowVisual: HTMLElement) {
        thingToHide.classList.add('hide');
        thingToShow.classList.remove('hide');
        thingToHideVisual.classList.add('hide');
        thingToShowVisual.classList.remove('hide');
        thingToShow.focus();
    }
}
