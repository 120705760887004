export const quickDonate = () => {
    const donationAmountsList = document.querySelector('#QD_amounts');
    const donationInput = document.querySelector('#DonationRequest_DonationAmount') as HTMLInputElement;

    if (donationAmountsList && donationInput) {
        const allRadios = Array.prototype.slice.call(document.querySelectorAll('input[name="DonationRequest.DonationAmount"]'));
        donationInput.addEventListener('change', () => {
            const newValue = parseInt(donationInput.value);
            if (newValue) {
                allRadios.forEach(radio => {
                    if (parseInt(radio.value) == newValue) {
                        radio.checked = true;
                    }
                    else {
                        radio.checked = false;
                    }
                })
            }
        });
        donationInput.addEventListener('blur', () => {
            if (parseInt(donationInput.value) <= 0) {
                donationInput.value = null;
            }
        });
        allRadios.forEach(radio => {
            if (radio.checked) {
                donationInput.value = radio.value;
            }
            radio.addEventListener('change', () => {
                allRadios.forEach(radio_ => {
                    if (radio_.checked) {
                        donationInput.value = radio_.value;
                    }
                });
            });
        });
    }
}