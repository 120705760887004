/// <reference path="index.d.ts" />

import { element } from "prop-types";


const applePayErrorMessageSelector = document.querySelector('.applePayErrorMessage') as HTMLInputElement;
const paymentForm = document.querySelector(".payment-form") as HTMLFormElement;
const termsAndConditions = document.querySelector('#termsAndConditions_cb') as HTMLInputElement;

export default class AdageApplePay {
    applePayButtonSelector = '#applepaybutton';
    static apiValidateUrl = '/ace-api/payment/applepay/validate';
    static apiPurchaseUrl = '/ace-api/payment/applepay/process';

    applePaySession: ApplePaySession;
    applePayPaymentRequest: ApplePayJS.ApplePayPaymentRequest;

    elementToGrabTotalQuery: string;
    totalValueAttributeName: string;

    constructor(totalElementQuery: string, attributeName: string, elementToCheck: HTMLButtonElement) {
        this.elementToGrabTotalQuery = totalElementQuery;
        this.totalValueAttributeName = attributeName;

        // Check if we have Apple Pay (i.e. Safari on iOS) before doing anything
        if (typeof (ApplePaySession) !== 'undefined' && ApplePaySession.canMakePayments()) {
            if (elementToCheck) {
                elementToCheck.classList.remove("hide");
            }
            else {
                var applePayButton = document.querySelector<HTMLInputElement>(this.applePayButtonSelector);
                if (applePayButton !== undefined && applePayButton !== null) {
                    applePayButton.removeAttribute("hidden");
                    applePayButton.addEventListener("click", this.onApplePayClick.bind(this));
                }

                if (termsAndConditions !== undefined && termsAndConditions !== null) {
                    if (applePayButton !== undefined && applePayButton !== null) {
                        applePayButton.setAttribute("disabled", "");
                        applePayButton.style.cursor = "not-allowed";
                    }
                    termsAndConditions.addEventListener('change', this.onTermsAndConditionsChange);
                }
            }
        }
    }

    /**
     * Create a preconfigured ApplePaySession with a custom amount
     * @param {any} amount The amount to charge the customer
     * @returns {ApplePaySession} An ApplePaySession ready to be used
     */
    createSession(amount: number): ApplePaySession {
        this.applePayPaymentRequest = {
            countryCode: 'US',
            currencyCode: 'USD',
            supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
            merchantCapabilities: ['supports3DS'],
            total: { label: 'Total Amount', amount: amount.toString() },
            requiredShippingContactFields: [
                'phone',
                'email'
            ],
            requiredBillingContactFields: [
                'postalAddress',
                'phone'
            ]
        };
        return new ApplePaySession(3, this.applePayPaymentRequest);
    }

    onApplePayClick(e)
/**
     * Called once the user presses the Apple Pay button
     * @param {any} e The button click event fired
     */ {
        applePayErrorMessageSelector.style.display = 'none';
        var totalEl = document.querySelector(this.elementToGrabTotalQuery);
        let total = parseFloat(totalEl.getAttribute(this.totalValueAttributeName));

        // I am the captain now
        if (e !== null) {
            e.preventDefault();
        }

        if (typeof (this.applePaySession) != 'undefined') {
            try {
                this.applePaySession.abort();
            }
            catch (exception) {
            }
        }
        // Create the session
        this.applePaySession = this.createSession(total);

        // Setup callbacks
        this.applePaySession.onvalidatemerchant = this.onValidateMerchant;
        this.applePaySession.onpaymentauthorized = this.onPaymentAuthorized;

        // Start it up
        this.applePaySession.begin();
    }

    /**
     * Callback to check with server validity of merchant
     * @param {any} e Event with validation url
     */
    onValidateMerchant = (e) => {
        fetch(AdageApplePay.apiValidateUrl,
            {
                body: JSON.stringify({ validationURL: e.validationURL }),
                credentials: 'include',
                headers: {
                    'content-type': 'application/json'
                },
                method: "POST"
            })
            .then(res => res.json())
            .then(json => {
                try {
                    this.applePaySession.completeMerchantValidation(json);
                } catch (e) {
                    applePayErrorMessageSelector.style.display = '';
                    console.log(e);
                }
            })
            .catch(error => {
                applePayErrorMessageSelector.style.display = '';
                //remove display none
                //TODO: hide spinner
                //hideSpinner();
            });
    }


    /**
     * Callback to complete payment once authorized
     * @param {any} e Event with billing information
     */
    onPaymentAuthorized = (e) => {
        var isDonation = false;
        var orderNotesList = [document.querySelector<HTMLInputElement>('textarea')];
        var orderNote = orderNotesList.map(elem => elem?.value)[0];
        if (!orderNote) {
            orderNote = "";
        }
        if (window.location.href.toLowerCase().indexOf("donations") > -1) {
            isDonation = true;
        }
        var totalEl = document.querySelector(this.elementToGrabTotalQuery);
        let total = parseFloat(totalEl.getAttribute(this.totalValueAttributeName));

        var payment = Object.assign({},
            e.payment,
            {
                options: {
                    // Grab the shipping method from only radios on page
                    shippingMethod: [document.querySelector<HTMLInputElement>('input')]
                        .filter(elem => elem.type === 'radio' && elem.checked).map(elem => parseInt(elem.value))[0],
                    // Grab order notes from only text area
                    orderNotes: orderNote,
                    isDonation: isDonation,
                    amount: total
                }
            });

        // Send the payment request to the API
        fetch(AdageApplePay.apiPurchaseUrl,
            {
                body: JSON.stringify({ "payment": payment, "session": this.applePayPaymentRequest }),
                headers: {
                    'content-type': 'application/json'
                },
                method: "POST",
                credentials: 'include'
            }).then(response => {
                if (response.ok) {
                    // Tell Apple the payment worked and go back to user code

                    this.applePaySession.completePayment(ApplePaySession.STATUS_SUCCESS);
                    paymentForm.submit();
                    var applePayButton = document.querySelector<HTMLInputElement>(this.applePayButtonSelector);
                    applePayButton.setAttribute("disabled", "");
                    applePayButton.style.cursor = "not-allowed";
                } else {
                    //Failure message from the API
                    applePayErrorMessageSelector.style.display = '';
                    response.json().then(result => {
                        applePayErrorMessageSelector.style.display = '';
                    });
                }
            }).catch(error => {
                // Nah
                applePayErrorMessageSelector.style.display = '';
            });
    }


    onFailedProcessingResponse(result) {

        if (result.refreshPage) {
            window.location.reload();
        } else {
            applePayErrorMessageSelector.style.display = '';
            //TODO: hide spinner
            //hideSpinner();
        }
    }

    onTermsAndConditionsChange = (e) => {
        var applePayButton = document.querySelector<HTMLInputElement>(this.applePayButtonSelector);
        if (termsAndConditions.checked) {
            applePayButton.removeAttribute("disabled");
            applePayButton.style.cursor = "pointer";
        } else {
            applePayButton.setAttribute("disabled", "");
            applePayButton.style.cursor = "not-allowed";
        }
    }
}