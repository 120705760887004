export default class CartTimer {
    private secondsRemaining: number;
    private intervalHandle: NodeJS.Timer;

    constructor() {
        var remainingTime: string = window["cartTimerSeconds"];
        if (remainingTime !== undefined) {
            this.tick = this.tick.bind(this);

            var startingMinutes = +remainingTime.split(':')[0];
            var startingSeconds = +remainingTime.split(':')[1];
  
            this.startCountDown();

            this.secondsRemaining = (+startingMinutes * 60) + startingSeconds;
            this.intervalHandle;
        }
    }

    tick() {
        var min = Math.floor(this.secondsRemaining / 60);
        var sec = this.secondsRemaining - (min * 60);

        var newSeconds = sec.toString();
        var newMinutes = min.toString();
        var expiring = false;

        if (sec < 10 && newSeconds.length < 2) {
            newSeconds = "0" + newSeconds;
        }
        if (min < 10 && newMinutes.length < 2) {
            newMinutes = "0" + newMinutes;
        }

        if (min === 0 && sec === 0) {
            clearInterval(this.intervalHandle);
            let cartTimerWrapper = document.getElementById("cart-timer");
            cartTimerWrapper.classList.add('expired');
        }

        let minutesSpan = document.getElementById("CartTimerMinutes");
        let secondsSpan = document.getElementById("CartTimerSeconds");

        minutesSpan.innerHTML = newMinutes;
        secondsSpan.innerHTML = newSeconds;

        this.secondsRemaining--
    }

    startCountDown() {
        this.intervalHandle = setInterval(this.tick, 1000);
    }
}